.menu {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.menu .bottom-icon {
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid #f0f0f0;
  height: 40px;
  line-height: 40px;
}
.menu .bottom-icon .header-icon {
  cursor: pointer;
  color: #000;
  font-size: 19px;
  text-align: left;
  width: 100%;
  padding-left: 34px;
}
.ant-layout-sider-children {
  position: relative;
}
