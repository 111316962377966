.login {
  z-index: 99;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}
.loginIframe {
  top: 0;
  left: 0;
}
.P_login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.P_login .ipt_title {
  margin-bottom: 113px;
}
.P_login .ipt_con {
  width: 360px;
}
.P_login .zhanwei {
  height: 1.5vh;
}
.P_login .ant-form-item-control-input {
  min-height: 22px;
}
.P_login .ant-form-item-control-input-content {
  display: flex;
  justify-content: flex-end;
}
.home .ant-card-body {
  padding: 0px;
  border: 0px;
  height: 100vh;
}
.home .ant-card-bordered {
  border: 0px;
}
.sliderContainer {
  margin-top: -6px !important;
  background-color: #141414 !important;
  border: #434343 !important;
}
.slider {
  background-color: #1f1f1f !important;
}
.sliderIcon {
  color: aquamarine !important;
}
