/** @format */
.card_sty {
  border: 1px solid #141414;
}
.card_sty:hover {
  border: 1px solid var(--primary);
}
.no_rad .ant-card {
  border-radius: 0px;
}
/* input 禁用时背景颜色 */
.ai .ant-input:disabled {
  background: #141414;
}
.site-input-group-wrapper .site-input-split {
  background-color: #141414 !important;
}
.site-input-group-wrapper .site-input-right {
  border-left-width: 0;
}
.site-input-group-wrapper .site-input-right:hover,
.site-input-group-wrapper .site-input-right:focus {
  border-left-width: 1px;
}
.site-input-group-wrapper .ant-input-rtl.site-input-right {
  border-right-width: 0;
}
.site-input-group-wrapper .ant-input-rtl.site-input-right:hover,
.site-input-group-wrapper .ant-input-rtl.site-input-right:focus {
  border-right-width: 1px;
}
/* 公共样式 */
.pointer {
  cursor: pointer;
}
.move {
  cursor: move;
}
.w16 {
  width: 16px;
}
.h16 {
  height: 16px;
}
.flex {
  display: flex;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-auto {
  flex: auto;
}
.flex-shrink0 {
  flex-shrink: 0;
}
.flex-justify-end {
  justify-content: flex-end;
}
.flex-justify-center {
  justify-content: center;
}
.flex-justify-between {
  justify-content: space-between;
}
.flex-justify-around {
  justify-content: space-around;
}
.flex-align-end {
  align-items: flex-end;
}
.flex-align-center {
  align-items: center;
}
.flex-align-baseline {
  align-items: baseline;
}
.tc {
  text-align: center;
}
.tl {
  text-align: left;
}
.tr {
  text-align: right;
}
.marT12 {
  margin-top: 12px;
}
.marT24 {
  margin-top: 24px;
}
.marL24 {
  margin-left: 24px;
}
.marR4 {
  margin-right: 4px;
}
.marR8 {
  margin-right: 8px;
}
.marB8 {
  margin-bottom: 8px;
}
.pd12 {
  padding: 12px;
}
.pd24 {
  padding: 24px;
}
.pdTB16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.pdRL24 {
  padding-left: 24px;
  padding-right: 24px;
}
.fs16 {
  font-size: 16px;
}
.fw500 {
  font-weight: 500;
}
.border {
  border: 1px solid black;
}
.border_w {
  border: 1px solid white;
}
.border_w2 {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.radius2 {
  border-radius: 2px;
}
.pos_rel {
  position: relative;
}
.pos_abs {
  position: absolute;
}
.mb {
  -webkit-text-stroke: 1.5px black;
}
