.TabPane {
  padding: 0 8px;
  border-radius: 100px;
}
.Pagination {
  padding: 16px 24px 16px 0;
}
.is-rotate {
  transform: rotate(180deg);
}
.ant-modal-close-x {
  color: rgba(255, 255, 255, 0.45);
}
.ant-modal-header {
  background-color: #141414;
  color: rgba(255, 255, 255, 0.85);
  /* border-bottom: 1px solid #303030; */
  border-radius: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-left: 1px solid #141414;
  border-right: 1px solid #141414;
}
.ant-modal-title {
  background-color: #141414;
  color: rgba(255, 255, 255, 0.85);
}
.ant-modal-body {
  background-color: #141414;
  color: rgba(255, 255, 255, 0.85);
}
.ant-modal-footer {
  background-color: #141414;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.85);
}
.ant-image-preview-operations {
  position: absolute;
  width: calc(100vw - 24px);
  height: 48px;
  display: flex;
  top: calc(100vh - 48px);
  margin-left: 24px;
}
