.disappear {
  animation-name: disappear_animation;
  animation-duration: 0.8s;
  /* animation-delay: 3s; */
}
@keyframes disappear_animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
