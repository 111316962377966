.MapComponents {
  width: 100%;
  height: 100%;
  z-index: 9;
}
.MapComponents .punctuation {
  width: 85px;
  height: 74px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.MapComponents .punctuation .Box {
  width: 100%;
  height: 65px;
  background: #000;
  border-radius: 6px;
  padding: 2.5px;
}
.MapComponents .punctuation .Box img {
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 255, 0.4);
  border-radius: 4px;
}
.MapComponents .punctuation .triangle-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #000;
}
