/* 全局样式 */
body {
  --primary: #1668DC;
  /* 品牌色 rgb 8,206,150 */
  /* 功能色 */
  --link-color: #05B191;
  /* 链接色 */
  --wx-link-color: #576B95;
  /* 微信链接色 */
  --success-color: #52c41a;
  /* 成功色 */
  --warning-color: #faad14;
  /* 警告色 */
  --error-color: #D32029;
  /* 错误色 */
  /* 字符颜色 */
  --char-main: #FFFFFF;
  /* 主 字符色*/
  /* 背景颜色 */
  --background-main: #333333;
  /* 主 背景色 */
  --background-block: #141414;
  /* 块 背景色 */
  /* 中性色 */
  --gray-1: #FFFFFF;
  --gray-3: #F5F5F5;
  --gray-9: #434343;
  --gray-13: #000000;
  /* 透明度 */
  --opacity-10: rgba(0, 0, 0, 0.1);
  --opacity-75: rgba(0, 0, 0, 0.75);
  --opacity-w-10: rgba(255, 255, 255, 0.1);
  /* 圆角 */
  --radius-1: 1px;
  --radius-3: 3px;
  --radius-8: 8px;
  --radius-10: 10px;
  --radius-23: 23px;
  --radius-40: 40px;
  /*  */
}
