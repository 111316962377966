.NodeMap .Back {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 64px;
  height: 40px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  top: 20px;
  left: 20px;
  z-index: 99;
}
