/** @format */
@import url("./theme_css.css");
/* 颜色样式....................................................................... */
@import url("./theme_css.css");
.col_primary {
  color: var(--primary) !important;
}
.col_error {
  color: var(--error-color) !important;
}
.bc_primary {
  background-color: var(--primary) !important;
}
/* 主 背景颜色 */
.col_main_bg {
  background-color: var(--background-main) !important;
}
/* 块 背景颜色 */
.col_block_bg {
  background-color: var(--background-block) !important;
}
/* 主 文本颜色 */
.col_main_text {
  color: var(--char-main) !important;
}
/* 次 文本颜色 */
.col_secondary_text {
  color: var(--char-main) !important;
  opacity: 0.45 !important;
}
/* 提示 文本颜色 */
.col_prompt_text {
  color: var(--char-main) !important;
  opacity: 0.4 !important;
}
/* 分隔线样式 */
.col_divider {
  border-bottom: 1rpx solid;
  border-color: var(--opacity-10);
}
/* 分隔线样式 - 暗色 */
.col_divider_w_top {
  border-top: 1px solid;
  border-color: var(--opacity-w-10);
}
/* 分隔线样式 - 暗色 */
.col_divider_w_btm {
  border-bottom: 1px solid;
  border-color: var(--opacity-w-10);
}
/* 圆角样式.................................................................. */
/* 块元素圆角 */
.radius_block {
  border-radius: var(--radius-10);
}
/* 颜色样式....................................................................... */
.col_primary {
  color: var(--primary) !important;
}
.bc_primary {
  background-color: var(--primary) !important;
}
/* 主 背景颜色 */
.col_main_bg {
  background-color: var(--background-main) !important;
}
/* 块 背景颜色 */
.col_block_bg {
  background-color: var(--background-block) !important;
}
/* 主 文本颜色 */
.col_main_text {
  color: var(--char-main) !important;
}
/* 次 文本颜色 */
.col_secondary_text {
  color: var(--char-main) !important;
  opacity: 0.45 !important;
}
/* 提示 文本颜色 */
.col_prompt_text {
  color: var(--char-main) !important;
  opacity: 0.4 !important;
}
/* 错误 文本颜色 */
.col_error_text {
  color: var(--error-color) !important;
}
/* 分隔线样式 */
.col_divider {
  border-bottom: 1rpx solid;
  border-color: var(--opacity-10);
}
/* 圆角样式.................................................................. */
/* 块元素圆角 */
.radius_block {
  border-radius: var(--radius-10);
}
