.TabPane {
  padding: 0 8px;
  border-radius: 100px;
}
.Pagination {
  padding: 16px 24px 16px 0;
}
.is-rotate {
  transform: rotate(180deg);
}
