/* 版心 */
.container {
  width: 1150px;
  margin: 0 auto;
}
/*
字体大小
*/
.fSize10 {
  font-size: 10px;
}
.fSize12 {
  font-size: 12px;
}
.fSize13 {
  font-size: 13px;
}
.fSize14 {
  font-size: 14px;
}
.fSize15 {
  font-size: 15px;
}
.fSize16 {
  font-size: 16px;
}
.fSize18 {
  font-size: 18px;
}
.fSize20 {
  font-size: 20px;
}
.fSize22 {
  font-size: 22px;
}
.fSize24 {
  font-size: 24px;
}
.fSize26 {
  font-size: 26px;
}
/*
字体颜色
*/
.coLFFF {
  color: #ffffff !important;
}
.coLF0_3 {
  color: rgba(255, 255, 255, 0.3);
}
.coLF0_45 {
  color: rgba(255, 255, 255, 0.45);
}
.coLF0_85 {
  color: rgba(255, 255, 255, 0.85);
}
.coL52C41A {
  color: #52c41a;
}
.coL37404D {
  color: #37404d;
}
.coL282828 {
  color: #282828;
}
.coLF2994A {
  color: #f2994a;
}
.coL327BFE {
  color: #327bfe;
}
.coL1890FF {
  color: #1890ff;
}
.coLEB5757 {
  color: #eb5757;
}
.coLFF4D4F {
  color: #ff4d4f !important;
}
.coL0_25 {
  color: rgba(0, 0, 0, 0.25);
}
.coL0_35 {
  color: rgba(0, 0, 0, 0.35);
}
.coL0_45 {
  color: rgba(0, 0, 0, 0.45);
}
.coL0_6 {
  color: rgba(0, 0, 0, 0.6);
}
.coL0_65 {
  color: rgba(0, 0, 0, 0.65);
}
.coL0_7 {
  color: rgba(0, 0, 0, 0.7);
}
.coL0_8 {
  color: rgba(0, 0, 0, 0.8);
}
.coL0_85 {
  color: rgba(0, 0, 0, 0.85);
}
.coLD9 {
  color: #d9d9d9;
}
.col69C0FF {
  color: #69c0ff;
}
.col26264E {
  color: #26264e;
}
.col177DDC {
  color: #177ddc;
}
/*
字体粗细
*/
.fWei400 {
  font-weight: 400;
}
.fWei500 {
  font-weight: 500;
}
.fWei600 {
  font-weight: 600;
}
.fWei700 {
  font-weight: 700;
}
/*
宽高
*/
.wid100 {
  width: 100%;
}
.wb30 {
  width: 30%;
}
.wb40 {
  width: 40%;
}
.w16 {
  width: 16px;
}
.w40 {
  width: 40px;
}
.w96 {
  width: 96px;
}
.w120 {
  width: 120px;
}
.w220 {
  width: 220px;
}
.w360 {
  width: 360px;
}
.hei100 {
  height: 100%;
}
.h16 {
  height: 16px;
}
.h22 {
  height: 22px;
}
.lh22 {
  line-height: 22px;
}
.h40 {
  height: 40px;
}
.lh40 {
  line-height: 40px;
}
.h54 {
  height: 54px;
}
.h64 {
  height: 64px;
}
.h80 {
  height: 80px;
}
.h180 {
  height: 180px;
}
.h406 {
  height: 406px;
}
.min-h50 {
  min-height: 50px;
}
.min-h64 {
  min-height: 64px;
}
.hb30 {
  height: 30%;
}
/*
display
*/
.disBlock {
  display: block !important;
}
/* .disBlock {display: block !important;} */
.disIn_Block {
  display: inline-block !important;
}
.verAlignMiddle {
  vertical-align: middle !important;
}
.disContents {
  display: contents !important;
}
.flex1 {
  flex: 1 1;
}
.disFlex {
  display: flex;
}
.flex_wrap {
  flex-wrap: wrap;
}
.flexDirCol {
  flex-direction: column;
}
.jusConCen {
  justify-content: center;
}
.jusConEnd {
  justify-content: flex-end;
}
.jusItemsCen {
  justify-items: center;
}
.jusConCenSB {
  justify-content: space-between;
}
.jusConCenSA {
  justify-content: space-around;
}
.aliItemsCen {
  align-items: center;
}
.aliItemsFend {
  align-items: flex-end;
}
.aliConCen {
  align-content: center;
}
.aliConStart {
  align-content: flex-start;
}
.jusItemsStart {
  justify-content: flex-start;
}
.flex {
  display: flex;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-auto {
  flex: auto;
}
.flex-jusconbet {
  display: flex;
  justify-content: space-between;
}
/*
margin
*/
.margauto {
  margin: auto;
}
.marg24 {
  margin: 24px;
}
.marg36 {
  margin: 36px;
}
.margT4 {
  margin-top: 4px;
}
.margT6 {
  margin-top: 6px;
}
.margT8 {
  margin-top: 8px;
}
.margT12 {
  margin-top: 12px;
}
.margT16 {
  margin-top: 16px;
}
.margT18 {
  margin-top: 18px;
}
.margT20 {
  margin-top: 20px;
}
.margT24 {
  margin-top: 24px;
}
.margT25 {
  margin-top: 25px;
}
.margT36 {
  margin-top: 36px;
}
.margT54 {
  margin-top: 54px;
}
.margT10B {
  margin-top: 10%;
}
.margLA {
  margin-left: auto;
}
.margL6 {
  margin-left: 6px;
}
.margL8 {
  margin-left: 8px;
}
.margL10 {
  margin-left: 10px;
}
.margL12 {
  margin-left: 12px;
}
.margL16 {
  margin-left: 16px;
}
.margL18 {
  margin-left: 18px;
}
.margL24 {
  margin-left: 24px;
}
.margL28 {
  margin-left: 28px;
}
.margL30 {
  margin-left: 30px;
}
.margL34 {
  margin-left: 34px;
}
.margL36 {
  margin-left: 36px;
}
.margL38 {
  margin-left: 38px;
}
.margL48 {
  margin-left: 48px;
}
.margL54 {
  margin-left: 54px;
}
.margRA {
  margin-right: auto;
}
.margR4 {
  margin-right: 4px;
}
.margR6 {
  margin-right: 6px;
}
.margR8 {
  margin-right: 8px;
}
.margR10 {
  margin-right: 10px;
}
.margR12 {
  margin-right: 12px;
}
.margR16 {
  margin-right: 16px;
}
.margR18 {
  margin-right: 18px;
}
.margR22 {
  margin-right: 22px;
}
.margR24 {
  margin-right: 24px;
}
.margR42 {
  margin-right: 42px;
}
.margR30 {
  margin-right: 30px;
}
.margR32 {
  margin-right: 32px;
}
.margR34 {
  margin-right: 34px;
}
.margR36 {
  margin-right: 36px;
}
.margR40 {
  margin-right: 40px;
}
.margR100 {
  margin-right: 100px;
}
.margB3 {
  margin-bottom: 3px;
}
.margB4 {
  margin-bottom: 4px;
}
.margB6 {
  margin-bottom: 6px;
}
.margB8 {
  margin-bottom: 8px;
}
.margB9 {
  margin-bottom: 9px;
}
.margB10 {
  margin-bottom: 10px;
}
.margB11 {
  margin-bottom: 11px;
}
.margB12 {
  margin-bottom: 12px;
}
.margB14 {
  margin-bottom: 14px;
}
.margB16 {
  margin-bottom: 16px;
}
.margB18 {
  margin-bottom: 18px;
}
.margB20 {
  margin-bottom: 20px;
}
.margB24 {
  margin-bottom: 24px;
}
.margB27 {
  margin-bottom: 27px;
}
.margB28 {
  margin-bottom: 28px;
}
.margB30 {
  margin-bottom: 30px;
}
.margB32 {
  margin-bottom: 32px;
}
.margB36 {
  margin-bottom: 36px;
}
.margB42 {
  margin-bottom: 42px;
}
.margB54 {
  margin-bottom: 54px;
}
.margB64 {
  margin-bottom: 64px;
}
.margRL8 {
  margin-left: 8px;
  margin-right: 8px;
}
.margRL24 {
  margin-left: 24px;
  margin-right: 24px;
}
.margTBA {
  margin-top: auto;
  margin-bottom: auto;
}
.margRLA {
  margin-right: auto;
  margin-left: auto;
}
.margLA {
  margin-left: auto;
}
/*
padding
*/
.padd {
  padding: 9px 24px;
}
.padd_8 {
  padding: 8px;
}
.padd_10 {
  padding: 10px;
}
.padd_12 {
  padding: 12px;
}
.padd_16 {
  padding: 16px;
}
.padd_18 {
  padding: 18px;
}
.padd_24 {
  padding: 24px;
}
.padd_32 {
  padding: 32px;
}
.paddHome {
  padding: 60px 108px 96px;
}
.paddPopup {
  padding: 32px 24px 24px;
}
.padd1 {
  padding: 1px;
}
.padd16 {
  padding: 16px;
}
.padd24 {
  padding: 24px;
}
.paddT0 {
  padding-top: 0px;
}
.paddT4 {
  padding-top: 4px;
}
.paddT12 {
  padding-top: 12px;
}
.paddT24 {
  padding-top: 24px;
}
.paddT60 {
  padding-top: 60px;
}
.paddR8 {
  padding-right: 8px;
}
.paddR12 {
  padding-right: 12px;
}
.paddR16 {
  padding-right: 16px;
}
.paddR18 {
  padding-right: 18px;
}
.paddR36 {
  padding-right: 36px;
}
.paddL8 {
  padding-left: 8px;
}
.paddL10 {
  padding-left: 10px;
}
.paddL12 {
  padding-left: 12px;
}
.paddL16 {
  padding-left: 16px;
}
.paddL18 {
  padding-left: 18px;
}
.paddL24 {
  padding-left: 24px;
}
.paddB4 {
  padding-bottom: 4px;
}
.paddB11 {
  padding-bottom: 11px;
}
.paddB12 {
  padding-bottom: 12px;
}
.paddB24 {
  padding-bottom: 24px;
}
.paddRL8 {
  padding-right: 8px;
  padding-left: 8px;
}
.paddRL12 {
  padding-right: 12px;
  padding-left: 12px;
}
.paddRL15 {
  padding-right: 15px;
  padding-left: 15px;
}
.paddRL16 {
  padding-right: 16px;
  padding-left: 16px;
}
.paddRL18 {
  padding-right: 18px;
  padding-left: 18px;
}
.paddRL20 {
  padding-right: 20px;
  padding-left: 20px;
}
.paddRL24 {
  padding-right: 24px;
  padding-left: 24px;
}
.paddRL63 {
  padding-right: 63px;
  padding-left: 63px;
}
.paddTB1 {
  padding-top: 1px;
  padding-bottom: 1px;
}
.paddTB4 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.paddTB5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.paddTB6 {
  padding-top: 6px;
  padding-bottom: 6px;
}
.paddTB0_85 {
  padding-top: 8.5px;
  padding-bottom: 8.5px;
}
.paddTB9 {
  padding-top: 9px;
  padding-bottom: 9px;
}
.paddTB10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.paddTB15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.paddTB16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.paddTB20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.paddTB24 {
  padding-top: 24px;
  padding-bottom: 24px;
}
/*
background
*/
.backColor327BFE {
  background-color: #327bfe !important;
}
/* 高亮 */
.backHighlight {
  background-color: rgba(94, 105, 123, 0.1);
}
.backF0F2F5 {
  background: #f0f2f5;
}
.back0D0C0C {
  background-color: #0d0c0c;
}
.backFAFAFA {
  background-color: #fafafa;
}
.back464646 {
  background-color: #464646;
}
.back37404D {
  background-color: #37404d;
}
.backColorF5 {
  background-color: #f5f5f5;
}
.back000 {
  background-color: #000000;
}
.backFFF {
  background-color: #ffffff;
}
.backD2323a {
  background-color: #515a6e;
}
.backFBFAFA {
  background-color: #fbfafa;
}
.backMoren {
  background-color: rgba(255, 0, 255, 0.4);
}
.backF6FFED {
  background-color: #f6ffed;
}
.backColorFFF {
  background-color: #ffffff;
}
.backColorF7F8FA {
  background-color: #f7f8fa;
}
.backColor1890FF {
  background-color: #1890ff;
}
.backColorE6F7FF {
  background-color: #e6f7ff;
}
.backColorRed {
  background-color: red;
}
.backColor0_3 {
  background-color: rgba(0, 0, 0, 0.3);
}
.backColor0_03 {
  background-color: rgba(0, 0, 0, 0.03);
}
.backColor1F {
  background-color: #1f1f1f;
}
.backColor14 {
  background-color: #141414;
}
.backColor26 {
  background-color: #262626;
}
.backColor43 {
  background-color: #434343;
}
.backColorD32029 {
  background-color: #d32029;
}
/*
position
*/
.posRel {
  position: relative;
}
.posAbs {
  position: absolute;
}
.pos_abs {
  position: absolute;
}
.pos_rel {
  position: relative;
}
.posFix {
  position: fixed;
}
.left {
  left: 0;
}
.top {
  top: 0;
}
/*
border-radius
*/
.borRad2 {
  border-radius: 2px;
}
.borRad3 {
  border-radius: 3px;
}
.borRad4 {
  border-radius: 4px;
}
.borRad6 {
  border-radius: 6px;
}
.borRad8 {
  border-radius: 8px;
}
.borRad10 {
  border-radius: 10px;
}
.borRad12 {
  border-radius: 12px;
}
.borRad13 {
  border-radius: 13px;
}
.borRad15 {
  border-radius: 15px;
}
.borRad32 {
  border-radius: 32px;
}
.borRad40 {
  border-radius: 40px;
}
/*
border
*/
.border {
  border: 1px solid black;
}
.borwhite {
  border: 1px solid white;
}
.borD0 {
  border: 0;
}
.bor0_1 {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.bor0_25 {
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.bor282828 {
  border: 1px solid #282828;
}
.borB7EB8F {
  border: 1px solid #b7eb8f;
}
.borD9D9D9 {
  border: 1px solid #d9d9d9;
}
.borD2323a {
  border: 1px solid #515a6e;
}
.bor434343 {
  border: 1px solid #434343;
}
.bor91D5FF {
  border: 1px solid #91d5ff;
}
.bor91D5FF {
  border: 1px solid #91d5ff;
}
.borDEDEDE_top {
  border-top: 1px solid #dedede;
}
.borDEDEDE_left {
  border-left: 1px solid #dedede;
}
.borDEDEDE_right {
  border-right: 1px solid #dedede;
}
.borDEDEDE_bottom {
  border-bottom: 1px solid #dedede;
}
.borBot0_6 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.borBot0_06 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.borBot0_1 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.borBot177DDC {
  border-bottom: 1px solid #177ddc;
}
.bor1890FF {
  border: 1px solid #1890ff;
}
.borRNo {
  border-right: 0px;
}
.borLNo {
  border-left: 0px;
}
.bor303030 {
  border: 1px solid #303030;
}
/*
超出隐藏
*/
.textccyc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.textccyc2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
}
.overFlowHidd {
  overflow: hidden;
}
.overflow_Y {
  overflow-y: scroll;
}
.autoY {
  overflow-y: auto;
}
/*
透明度
*/
.opacity1 {
  opacity: 1;
}
.opacity0_04 {
  opacity: 0.04;
}
.opacity0_05 {
  opacity: 0.05;
}
.opacity0_25 {
  opacity: 0.25;
}
.opacity0_3 {
  opacity: 0.3;
}
.opacity0_4 {
  opacity: 0.4;
}
.opacity0_45 {
  opacity: 0.45;
}
.opacity0_6 {
  opacity: 0.6;
}
.opacity0_64 {
  opacity: 0.64;
}
.opacity0_7 {
  opacity: 0.7;
}
.opacity0_85 {
  opacity: 0.85;
}
/*
box-shadow
*/
.boxSha {
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.12);
}
.boxSha_0_4 {
  box-shadow: 0px 0px 6px 1px rgba(115, 143, 147, 0.4);
}
.boxSha_0_6 {
  box-shadow: 0px 0px 6px 1px rgba(115, 143, 147, 0.6);
}
.boxSha_0_12 {
  box-shadow: 0px 0px 6px 1px rgba(115, 143, 147, 0.12);
}
.boxSha {
  box-shadow: 2wpx 2px 6px 1px rgba(115, 143, 147, 0.6);
}
/*
text-align
*/
.tt_a_cen {
  text-align: center;
}
.tt_a_r {
  text-align: right;
}
/*
cursor
*/
.curPointer {
  cursor: pointer;
}
/*
蒙版居中
*/
.Mask {
  z-index: 99;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.MaskClick {
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100%;
}
.borbox {
  box-sizing: border-box;
}
/* tabs */
.ant-tabs-nav {
  padding-left: 24px;
}
.scrwidno {
  scrollbar-width: none;
}
/* overflow */
.hidden {
  overflow: hidden;
}
/*
主题颜色
*/
.backcol {
  background-color: #141414;
}
.menubackcol {
  background-color: #1f1f1f;
}
.tablebackcol {
  background-color: #000000;
}
.jb {
  background-image: linear-gradient(to bottom, #262626, #000000);
}
.omit {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.nowrap {
  white-space: nowrap;
}
