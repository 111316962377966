/**
 * 暗黑主题
 *
 * @format
 */
@import "~antd/dist/antd.dark.css";
/* 明亮主题 */
/* @import '~antd/dist/antd.css'; */
/* card   边框线 */
:where(.css-dev-only-do-not-override-8urctq).ant-card-bordered {
  border: 0px;
}
/* menu   右边框 */
:where(
    .css-dev-only-do-not-override-8urctq
  ).ant-menu-light.ant-menu-root.ant-menu-inline,
:where(
    .css-dev-only-do-not-override-8urctq
  ).ant-menu-light.ant-menu-root.ant-menu-vertical {
  border: 0px;
}
* {
  font-family: -apple-system, BlinkMacSystemFont, "Microsoft YaHei UI", "Microsoft YaHei", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
#webpack-dev-server-client-overlay {
  display: none !important;
}
.ant-table-cell {
  padding: 0;
}
